//Test connection (gives error)
export const checkDatabaseConnection = async () => {
    try {
        const response = await fetch('/api/testConnection');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();  // Assuming the server responds with JSON
        console.log('Database connection test result:', data);

        return data;
    } catch (error) {
        console.error('Failed to test database connection:', error);
    }
};

export const AuthApiFibsen = async (username, password) => {
    //https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/login
    //const response = await fetch('/api/fibsenAuthLogin', {
    const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password })
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
    
      //console.log("fetchHortatech retrieved JSON: ",retrievedJSON);
    return await response.json();
    
};

export const fetchPressureData = async ({ entityId, startDate, endDate, min_pressure, max_pressure, maxResults, category, accessToken, refreshToken}) => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ entityId, startDate, endDate, min_pressure, max_pressure, maxResults, category, accessToken, refreshToken })
        });

        //Handle non-200 responses
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }

        //If response is OK, parse and return the JSON data
        const data = await response.json();  // Call response.json() only once
        //console.log("apiFibsenCalls fetchPressureData retrieved JSON: ", data);
        return data;

    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  // Re-throw to let calling functions handle it
    }
};

export const fetchTemperatureData = async ({ entityId, startDate, endDate, min_temperature, max_temperature, maxResults, category, accessToken, refreshToken}) => {
    //console.log("apiFibsenCalls fetchTemperatureData category, accessToken: ", category, accessToken);
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ entityId, startDate, endDate, min_temperature, max_temperature, maxResults, category, accessToken, refreshToken })    
        });
        console.log("apiFibsenCalls fetchTemperatureData response: ", response);
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const data = await response.json();  // Call response.json() only once
        //console.log("apiFibsenCalls fetchTemperatureData retrieved JSON: ", data);
        return data;

    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch temperature data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};

export const fetchFlowData = async ({entityId, startDate, endDate, min_flow, max_flow, maxResults, category, accessToken, refreshToken}) => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ entityId, startDate, endDate, min_flow, max_flow, maxResults, category, accessToken, refreshToken })    
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const data = await response.json();  // Call response.json() only once
        //console.log("apiFibsenCalls fetchFlowData retrieved JSON: ", data);
        return data;

    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch flow data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }
};

export const fetchDevicesData = async (category, accessToken, refreshToken) => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ category, refreshToken })
    
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const data = await response.json();  // Call response.json() only once
        //console.log("apiFibsenCalls fetchDevicesData: ",retrievedJSON);
        return data;


    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};

export const fetchSensorsData = async () => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getAllSensors?action=getSensorsData', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const sensorsData = await response.json();  
        console.log("apiFibsenCalls fetchSensorsData getSensorsData: ",sensorsData);
        return sensorsData;


    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};

export const fetchSensorsDataLatest5 = async () => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getAllSensors?action=getLatestFive', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const sensorsData = await response.json();  
        //console.log("apiFibsenCalls fetchSensorsDataLast5: ",sensorsData);
        return sensorsData;


    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};

export const fetchSensorsDataLast = async () => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getAllSensors?action=getLast', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const sensorsData = await response.json();  
        //console.log("apiFibsenCalls fetchSensorsDataLatest: ",sensorsData);
        return sensorsData;


    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};

export const fetchSensorsDataLatest200 = async () => {
    try {
        const response = await fetch('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/sensors/getAllSensors?action=getLatest200', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        });
    
        if (!response.ok) {
            const errorData = await response.json();  // Assuming the server sends JSON with an error message
            throw new Error(`Server error ${response.status}: ${errorData.message || response.statusText}`);
        }
        
        const sensorsData = await response.json();  
        //console.log("apiFibsenCalls fetchSensorsDataLatest: ",sensorsData);
        return sensorsData;


    } catch (error) {
        // Log or handle errors in error catching
        console.error('Failed to fetch pressure data:', error.message);
        throw error;  //Re-throw to let calling functions handle it
    }    
};